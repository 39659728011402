<template>
  <v-container v-if="!_.isEmpty(technicianEditable)">
    <!-- Banner header -->
    <Banner :title="technicianEditable.role === 'TECHNICIAN' ? 'Técnico' : 'Super técnico'">
      <template v-slot:actions>
        <BtnDisableTechnician
          v-if="permissionsBannerActions.disable.includes(user.role) && technicianEditable.status !== 'DISABLED'"
          :technician_data="{ id: technicianEditable.id }"
        />
        <v-chip v-if="technicianEditable.status === 'DISABLED'" color="error">DESHABILITADO</v-chip>
      </template>
    </Banner>

    <!-- Form profile data -->
    <v-card class="my-8 pa-5">
      <v-card-text class="pa-5">
        <v-form v-if="!_.isEmpty(technicianEditable)" ref="formEditTechnician" v-model="formEditTechnicianValid">
          <v-text-field v-model="technicianEditable.first_name" label="Nombre" :rules="formRules.textRules" :readonly="!editable" />
          <v-text-field v-model="technicianEditable.last_name" label="Apellidos" :rules="formRules.textRules" :readonly="!editable" />
          <v-text-field v-model="technicianEditable.email" type="email" label="Email" :rules="formRules.emailRules" :readonly="!editable" />
          <v-text-field
            v-model="technicianEditable.identity_document"
            label="Documento de identidad"
            :rules="formRules.textRules"
            :readonly="!editable"
          />
          <v-text-field v-model="technicianEditable.phone" label="Teléfono" :rules="formRules.phoneRules" :readonly="!editable" />
          <v-select
            v-if="technicianEditable.role === 'TECHNICIAN'"
            v-model="technicianEditable.technician.office_id"
            :items="offices.filter((e) => e.country.id === technician.country)"
            item-text="name"
            item-value="id"
            label="Oficina"
            readonly
            :rules="[(v) => !!v || 'Campo obligatorio']"
          />
          <v-select
            v-if="technicianEditable.role === 'SUPERTECHNICIAN'"
            v-model="technicianEditable.technician.partner_id"
            :items="partners.filter((e) => e.country.id === technician.country)"
            item-text="name"
            item-value="id"
            label="Partner"
            readonly
            :rules="[(v) => !!v || 'Campo obligatorio']"
          />
          <CountryAutocomplete :listener="technician" :countryId="technicianEditable.country" :editable="false" />
          <v-autocomplete
            v-model="technicianEditable.city"
            :items="cities"
            item-text="name"
            item-value="id"
            label="Ciudad"
            :rules="formRules.textRules"
            :readonly="!editable"
          />
          <v-text-field
            v-if="!_.isEmpty(technicianEditable.city)"
            v-model="technicianEditable.zone"
            label="Zona"
            :readonly="!editable"
            :rules="formRules.textRules"
          />
          <v-text-field v-model="technicianEditable.address.name" label="Dirección" :rules="formRules.textRules" :readonly="!editable" />
          <v-text-field
            v-model="technicianEditable.password"
            label="Contraseña (introducir sólo si se desea cambiar)"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            :readonly="!editable"
            :rules="formRules.passwordNotRequiredRules"
            @click:append="showPassword = !showPassword"
          />
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-icon
          v-if="!editable && permissionsCardsActions.profileData.edit.includes(user.role) && technicianEditable.status !== 'DISABLED'"
          @click="editable = true"
        >
          edit
        </v-icon>
        <v-btn v-if="editable" text @click="(editable = false), formatFields()">Cancelar</v-btn>
        <v-btn v-if="editable" text @click="edit">Guardar</v-btn>
      </v-card-actions>
    </v-card>

    <!-- Form profile image -->
    <v-card class="my-8">
      <v-toolbar color="primary" dense>Imagen de perfil</v-toolbar>
      <v-card-text class="pa-5">
        <v-form ref="formUploadProfileImage" v-model="formUploadProfileImageValid">
          <LazyImage :img="this.profileImageUrl ? this.profileImageUrl : ''" :lazy="lazyProfileImage" />
          <v-file-input
            v-if="technicianEditable.status !== 'DISABLED'"
            v-model="profileImage"
            :accept="['.jpeg', '.png', '.jpg']"
            truncate-length="100"
            :clearable="false"
            prepend-icon="image"
            label="Imagen de perfil"
            :rules="formRules.imageRequiredRules"
          />
        </v-form>
      </v-card-text>
      <v-card-actions
        v-if="permissionsCardsActions.profileImage.edit.includes(user.role) && technicianEditable.status !== 'DISABLED'"
        class="justify-end"
      >
        <v-btn text @click="uploadProfileImage">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import listeners from "@/firebase/listeners";
import _ from "lodash";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import CommonMixin from "@/mixins/CommonMixin";
import Banner from "@/components/elements/Banner";
import LazyImage from "@/components/elements/LazyImage";
import BtnDisableTechnician from "@/components/elements/technician/BtnDisableTechnician";
import CountryAutocomplete from "@/components/elements/locations/CountryAutocomplete.vue";

export default {
  name: "Technician",
  components: {
    Banner,
    LazyImage,
    BtnDisableTechnician,
    CountryAutocomplete
  },
  mixins: [FormRulesMixin, CommonMixin],
  data() {
    return {
      // Banner actions permissions
      permissionsBannerActions: {
        disable: ["SUPERADMIN"]
      },

      // Cards actions permissions
      permissionsCardsActions: {
        profileData: {
          edit: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN"]
        },
        profileImage: {
          edit: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN"]
        }
      },

      // Form edit investor
      editable: false,
      formEditTechnicianValid: false,
      technicianEditable: {},
      showPassword: false,

      // Form upload profile image
      formUploadProfileImageValid: false,
      profileImage: null,
      profileImageUrl: "",
      lazyProfileImage: false
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      technician: (state) => state.technicians.technician,
      partners: (state) => state.partners.partners,
      offices: (state) => state.offices.offices,
      cities: (state) => state.locations.cities
    }),
    _() {
      return _;
    }
  },
  watch: {
    technician() {
      this.formatFields();
    },
    editable() {
      this.$store.commit("SET_EDITING", this.editable);
    }
  },
  async created() {
    this.$store.commit("SET_LOADING", true);
    await listeners.technician(this.$route.params.id);
    // Push back if id does not exist
    if (this._.isEmpty(this.technician) || this.technician.id !== this.$route.params.id) {
      this.$router.push("/tecnicos");
      this.$store.commit("SET_LOADING", false);
      return;
    }
    await this.$store.dispatch("partners/getPartners");
    await this.$store.dispatch("offices/getOffices", { filter: { "country.id": this.technician.country } });
    await this.$store.dispatch("locations/getCities", { filter: { country: this.technician.country } });
    await this.formatFields();
    this.$store.commit("SET_LOADING", false);
  },
  methods: {
    // Format fields editables
    async formatFields() {
      this.technicianEditable = this._.cloneDeep(this.technician);
      if (this.technicianEditable.photo) this.profileImageUrl = await this.getFileFromStorage(this.technicianEditable.photo);
    },

    // Edit technician
    async edit() {
      if (this.$refs.formEditTechnician.validate()) {
        let technicianEditableFormattedData = {
          id: this.technicianEditable.id,
          first_name: this.technicianEditable.first_name,
          last_name: this.technicianEditable.last_name,
          email: this.technicianEditable.email,
          identity_document: this.technicianEditable.identity_document,
          phone: this.technicianEditable.phone,
          address: this.technicianEditable.address,
          city: this.technicianEditable.city,
          zone: this.technicianEditable.zone
        };

        if (this.technicianEditable.password) technicianEditableFormattedData.password = this.technicianEditable.password;
        await this.$store.dispatch("technicians/editTechnician", technicianEditableFormattedData);

        await this.formatFields();
        this.editable = false;
      }
    },

    // Valid and upload image of technician's profile
    async uploadProfileImage() {
      if (this.$refs.formUploadProfileImage.validate()) {
        const user_id = this.technicianEditable.id;
        const file = await this.toBase64(this.profileImage);

        await this.$store.dispatch("addUserPhoto", { user_id, file });
        this.lazyProfileImage = true;
        this.profileImage = null;
        this.$refs.formUploadProfileImage.resetValidation();
      }
    }
  }
};
</script>
