<template>
  <div>
    <!-- Data table investments -->
    <v-card class="my-8">
      <v-toolbar color="primary" dense>Inversiones</v-toolbar>
      <DataTable :headers="tableInvestmentsHeaders" :items="investments" :hideDefaultFooter="false" :disablePagination="false">
        <template v-slot:[`item.id`]="{ item }">
          <a @click="handlerGetInvestmentInfo(item.id)">{{ item.id }}</a>
        </template>
        <template v-slot:[`item.investor.name`]="{ item }">
          <a @click="pushToInvestorSheet(item.investor.id)">
            <u>{{ item.investor.name }}</u>
          </a>
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          {{ currency(item.amount, { fromCents: true }) }}
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ formatDateL(item.created_at) }}
        </template>
        <template v-if="projectEditable.status === 'INVESTABLE' || projectEditable.status === 'FUNDED'" v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="investmentPermissions.delete.includes(user.role)"
            color="red"
            @click="(investmentSelected = item), (dialogDeleteInvestment = true)"
          >
            delete
          </v-icon>
        </template>
      </DataTable>
    </v-card>

    <!-- Dialog investment info -->
    <DialogBox v-if="investment_info" :model="dialogInvestmentInfo">
      <template slot="toolbar">
        <span>Datos de la inversión - {{ investment_info.id }}</span>
      </template>
      <template slot="content">
        <div class="secondary">
          <SimpleTableFields text="Moneda:" :value="investment_info.currency" :divider="false" />
          <SimpleTableFields text="Tipo de cambio:" :value="investment_info.exchange_rate" />
        </div>
        <SimpleTableFields
          text="Cantidad invertida:"
          :value="currency(investment_info.amount_invested, { fromCents: true })"
          :divider="false"
        />
        <SimpleTableFields
          :text="`Comisión (${investment_info.commission}%):`"
          :value="currency(investment_info.amount_commission, { fromCents: true })"
        />
        <SimpleTableFields text="Cantidad depositada:" :value="currency(investment_info.amount, { fromCents: true })" :divider="false" />
        <SimpleTableFields text="Cantidad recuperada:" :value="currency(investment_info.amount_repaid, { fromCents: true })" />
        <SimpleTableFields
          text="Balance:"
          :value="
            currency(investment_info.amount_repaid, { fromCents: true }).subtract(currency(investment_info.amount, { fromCents: true }))
          "
          :divider="false"
        />
        <router-link v-if="investment_info.money_transfer_id" :to="`/envio/${investment_info.money_transfer_id}`">Ver envío</router-link>
      </template>
      <template slot="actions">
        <v-btn text @click="dialogInvestmentInfo = false">Cerrar</v-btn>
      </template>
    </DialogBox>

    <!-- Dialog delete investment -->
    <DialogBox :model="dialogDeleteInvestment" :color="'error'" isdark>
      <template slot="toolbar">
        <span>¿Estás seguro de eliminar esta inversión?</span>
      </template>
      <template slot="actions">
        <v-btn text @click="dialogDeleteInvestment = false">Cerrar</v-btn>
        <v-btn text @click="(dialogDeleteInvestment = false), deleteInvestment()">eliminar</v-btn>
      </template>
    </DialogBox>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DataTable from "@/components/elements/DataTable";
import DialogBox from "@/components/elements/DialogBox";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import SimpleTableFields from "@/components/elements/SimpleTableFields.vue";
import * as currency from "currency.js";

export default {
  components: {
    DataTable,
    DialogBox,
    SimpleTableFields
  },
  mixins: [FormatDateMixin],
  props: {
    projectEditable: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      // Investments datatable
      tableInvestmentsHeaders: [
        { text: "Id", value: "id", sortable: false },
        { text: "Inversor", value: "investor.name", sortable: false },
        { text: "Cantidad invertida", value: "amount", sortable: false },
        { text: "Fecha", value: "created_at" },
        { text: "", value: "actions", sortable: false }
      ],

      // Cards actions permissions
      investmentPermissions: {
        delete: ["SUPERADMIN", "ADMIN"]
      },

      // Repayment selected to edit/delete
      investmentSelected: null,

      // Dialogs
      dialogDeleteInvestment: false,
      dialogInvestmentInfo: false
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      investment_info: (state) => state.investments.investment_info,
      investments: (state) => state.investments.investments
    }),
    currency() {
      return currency;
    }
  },
  async created() {
    await this.$store.dispatch("investments/getInvestments", {
      filter: { "project.id": this.projectEditable.id }
    });
  },
  methods: {
    // Get investor type and push to his sheet
    async pushToInvestorSheet(investorId) {
      const investor = await this.$store.dispatch("investors/getInvestor", { filter: { id: investorId } });
      this.$router.push(`/inversores/${investor.type.toLowerCase()}/${investorId}`);
    },

    async deleteInvestment() {
      await this.$store.dispatch("investments/deleteInvestment", this.investmentSelected.id);
      await this.$store.dispatch("investments/getInvestments", {
        filter: { "project.id": this.projectEditable.id }
      });
    },

    async handlerGetInvestmentInfo(id) {
      await this.$store.dispatch("investments/getInvestmentInfo", id);
      this.dialogInvestmentInfo = true;
    }
  }
};
</script>
